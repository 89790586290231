<template>
  <div class="card card-body shadow-sm infomations-list">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
    <app-basic-table
      ref="basic_table"
      :table-name="$t('restaurant.list_title')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.KYY_RESTAURANT_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row">
          <div class="col-xl-6 col-lg-12 col-sm-12">
            <div class="form-group">
              <label class="col-xl-6 col-lg-6 col-sm-8">{{ filters.name.label }}</label>
              <div class="col-xl-6 col-lg-6 col-sm-12 gr-icheck mt-0">
                <app-input :name="filters.name.name" input-style="normal"
                           v-model="filters.name.value"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common_vn.create") }}
        </button>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common_vn.detail") }}
          </button>
        </td>
      </template>

      <template v-slot:body-cell-image_path="props">
        <td class="app-align-middle" style="display: flex;justify-content: center;">
          <img :src="props.row.image.url" v-if="props.row.image.path" style="width: 100px; height: 100px">
        </td>
      </template>
    </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {INFORMATION_CONSTANT} from "../../../constants/information";
  export default {
    name: "RestaurantList",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          name: {
            name: "name",
            condition: "like",
            label: this.$t("restaurant.name")
          },
        },
        columns: [
          {name: "name", label: this.$t("restaurant.name"), sortable: true, textAlign: 'text-center'},
          {name: "image_path", label: this.$t("restaurant.logo"), textAlign: 'text-center'},
          {name: "hotline", label: this.$t("restaurant.hotline"), sortable: true, textAlign: 'text-center'},
          {name: "bank_name", label: this.$t("restaurant.bank_name"), sortable: true, textAlign: 'text-center'},
          {name: "bank_number", label: this.$t("restaurant.bank_number"), textAlign: 'text-center'},
          {name: "bank_account_name", label: this.$t("restaurant.bank_account_name"), sortable: true, textAlign: 'text-center'},
          {name: "edit", label: this.$t("common_vn.action"), textAlign: 'text-center'},
        ],
        meta: [],
        endPoint: ''
      };
    },
    watch : {
      "filters.from_date_between.value" : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
      "filters.to_date_between.value" : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
    },
    methods: {
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.KYY_RESTAURANT_CREATE})
      },
      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.KYY_RESTAURANT_EDIT, params: {id: entry.id}})
      },
      onResetAllSearch() {
        this.$router.push({name: this.$route.name,
          query: {
            'filters.infor_type.equal' : 'all',
            'filters.status_publishing.equal' : 'all'
          }
        })
      },
      onSearch() {
        // this.getTourSelectName();
      },
    },
    mounted: function () {
      // this.$request.get(this.ENDPOINT.RESTAURANT_LIST).then(res => {
      //   this.meta = {
      //     data: res.data,
      //   }
      // })
    },
  }
</script>
